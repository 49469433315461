.inputForm {

}
.textarea {

}
.fullWidth {
    display: flex;
}
.loading {
    font-style: italic;
    color: #666;
}

.faqList {
    margin-top: 20px;
}

.faqItem {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.faqItem:last-child {
    border-bottom: none;
}
